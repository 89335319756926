import React, { useCallback, useEffect, useState, useRef } from "react";
import debounce from "lodash.debounce";
import { Button } from "elements";

import { AppLayout } from "components/index";
import {
  AIAssistantModal,
  AddOptionLink,
  Compare,
  DecisionDetailHeader,
  FinalizeDecision,
  ManageCriteria,
  WinnerModal,
} from "scenes/DecisionMatrix";
import { Select } from "../../components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classes from "./optionDetail.module.scss";
import Placeholder from "./Placeholder.jpg";
import cc from "classnames";
import useWindowSize from "../../../../utils/useWindowSize";
import { GetCriteria } from "redux/DecisionMatrix/GetCriteria/action";
import { UpdateOptionCriteria } from "redux/DecisionMatrix/UpdateOptionCriteria/action";
import { UpdateOption } from "redux/DecisionMatrix/UpdateOptions/action";
import { GetOptionDetail } from "redux/DecisionMatrix/GetOptionDetail/action";
import { EditDecisionModal } from "components/EditDecisionModal/EditDecisionModal";
import { GetDecisionDetails } from "redux/DecisionMatrix/GetDecisionDetails/action";
import { GetShortlists } from "redux/DecisionMatrix/GetShortlists/action";
import { Col, Row } from "antd";
import HelpPopup from "components/HelpPopup";
import { FactsModal } from "scenes/DecisionMatrix/components/FactsModal/FactsModal";
import { SetGenerateFactsNull } from "redux/DecisionMatrix/GenerateFacts/action";
import { Tabs } from "antd";
import "./override.scss";
import CompareSummary from "components/CompareSummary/CompareSummary";
import { UpdateCriteriaRows } from "redux/DecisionMatrix/UpdateCriteriaRows/action";

const OptionDetail = () => {
  const { success: userDecision } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );
  const { success: userCriteria } = useSelector(
    (state) => state.getCriteriaReducer
  );

  const { success: userDecisions, loading } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );

  const dispatch = useDispatch();
  const { decisionId, optionId } = useParams();

  const [aiAssistant, setAiAssistant] = useState(false);
  const [isShowFinalizeDecisin, setIsShowFinalizeDecisin] = useState(false);
  const [isShowWinner, setIsShowWinner] = useState(false);
  const [option, setOption] = useState({});
  const [pros, setPros] = useState([]);
  const [cons, setCons] = useState([]);
  const [criterias, setCriterias] = useState([]);
  const [activeCriteria, setActiveCriteria] = useState({});
  const [activeCriterias, setActiveCriterias] = useState([]);
  const [selectedCriteria, setSelectedCriteria] = useState("");
  const [activeOptionCriteria, setActiveOptionCriteria] = useState("");
  const [optionCriteriaState, setOptionCriteriaState] = useState([]);
  const [facts, setFacts] = useState("");
  const [notes, setNotes] = useState("");
  const [selectIndex, setSelectIndex] = useState(true);
  const [isAddLink, setIsAddLink] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isFactModalOpen, setIsFactModalOpen] = useState(false);
  const [selectedCriterias, setSelectedCriterias] = useState("");
  const [isTrue, setIsTrue] = useState();
  const $criterias = useRef(null);

  const { width } = useWindowSize();
  const { columnsToShow } = useSelector((state) => state.columnsToShowReducer);
  useEffect(() => {
    if (!decisionId) return;
    if (columnsToShow?.isShortlisted) {
      dispatch(GetShortlists(decisionId));
    } else {
      dispatch(GetDecisionDetails(decisionId));
    }
  }, [columnsToShow]);

  useEffect(() => {
    if (optionId) {
      dispatch(GetOptionDetail(optionId));
    }
  }, [optionId]);

  useEffect(() => {
    dispatch(GetCriteria(decisionId));
  }, [decisionId]);

  useEffect(() => {
    if (userDecision?.criteria?.length) {
      setOptionCriteriaState([...userDecision?.criteria]);
    }
  }, [userDecision?.criteria]);

  useEffect(() => {
    if (activeOptionCriteria?.id) {
      setFacts(activeOptionCriteria?.facts);
    }
  }, [activeOptionCriteria]);

  useEffect(() => {
    if (userDecision?.options?.length) {
      setOption({
        ...userDecision?.options?.find((o) => o?.id === +optionId),
      });
      setNotes(userDecision?.options?.find((o) => o?.id === +optionId)?.notes);
    }
  }, [userDecision?.options, optionId]);

  useEffect(() => {
    if (userDecision?.criteria?.length === 0 && option?.decision) {
      dispatch(GetCriteria(option?.decision));
    }
  }, [option]);

  useEffect(() => {
    if (userDecision?.options && optionId) {
      setActiveOptionCriteria(
        userDecision?.options
          ?.find((item) => item?.id == optionId)
          ?.option_criteria?.find(
            (item) => item?.criteria === activeCriteria?.id
          )
      );
    }
  }, [activeCriteria, userDecision, selectedCriterias]);

  useEffect(() => {
    if (userDecision?.criteria && userDecision?.criteria?.length) {
      setCriterias(userDecision?.criteria);
      setActiveCriteria(
        userDecision?.criteria
          ?.find((item) => item?.id == activeCriteria?.id)
        || userDecision?.criteria[0]
      );
      setSelectedCriteria(userDecision?.criteria[0]?.id);

      onCriteriasScrollHandle();
    }
    setActiveCriterias(
      userDecision?.criteria
    )
  }, [userDecision?.criteria]);

  const onCriteriasScrollHandle = () => {
    const $area = $criterias?.current;

    if (!$area) return;

    if ($area?.scrollLeft === 0) {
      $area?.classList.remove(classes?.scrollLeft);
    } else if ($area?.scrollLeft + $area?.clientWidth === $area?.scrollWidth) {
      $area?.classList?.remove(classes?.scrollRight);
    } else {
      $area?.classList?.add(classes?.scrollLeft, classes?.scrollRight);
    }
  };

  const onFactsChangeHandle = (e) => {
    if (!activeOptionCriteria?.id)
      return;

    setFacts(e?.target?.value);
  };

  const handleFactsReset = () => {
    setFacts(activeOptionCriteria?.facts);
  };

  const handleFactsSave = () => {
    if (!activeOptionCriteria?.id)
      return;

    dispatch(
      UpdateOptionCriteria(
        {
          facts
        },
        activeOptionCriteria?.id,
        false,
        false,
        columnsToShow?.isShortlisted
      )
    );
  };

  const updateFacts = useCallback(
    debounce((body, id) => {
      // let decId = activeOptionCriteria?.criteria?.decision;
      dispatch(
        UpdateOptionCriteria(
          body,
          id,
          false,
          false,
          columnsToShow?.isShortlisted
        )
      );
    }, 1000),
    []
  ); 

  const onNotesChangeHandle = (event) => {
    setNotes(event?.target?.value);
  };

  const handleResetNotes = () => {
    setNotes(userDecision?.options?.find((o) => o?.id === +optionId)?.notes);
  };

  const handleUpdateNotes = () => {
    dispatch(UpdateOption({ id: optionId, body: { notes } }));
  };

  /* const updateNotes = useCallback(
    debounce((notes) => {
      dispatch(UpdateOption({ id: optionId, body: { notes } }));
    }, 1000),
    []
  ); */

  const onCloseAIModal = () => {
    setAiAssistant(false);
  };

  useEffect(() => {
    if (!userDecision?.criteria) return;
    // const found = userDecision?.criteria
    //   ?.filter((item) => item?.active)
    //   ?.map((c) => activeCriteria?.id === c?.id);
    const found = userDecision?.criteria?.find(
      (e) => e?.id === activeCriteria?.id
    );

    // setActiveCriteria(found);
    setSelectedCriterias(found);
  }, [userDecision]);

  const setOptionCriteriaProsCons = (key, value) => {
    if (value) {
      const found = userDecision?.criteria?.find((e) => e?.id == value);
      setActiveCriteria(found);
      setSelectedCriterias(found);
      // setAISelectedCriteria(value);
    }
  };

  // const [popoverID, setPopoverID] = useState(null);
  // const [popover1ID, setPopover1ID] = useState(null);

  const factModal = () => {
    setIsFactModalOpen(true);
  };

  const handleFactsModal = (criteriaSelected, facts) => {
    if (selectedCriterias === criteriaSelected) {
      setFacts(facts);
      updateFacts({ facts }, activeOptionCriteria?.id);
      setIsFactModalOpen(false);
      dispatch(SetGenerateFactsNull(null));
    }
  };

  const handleRubricSave = (rubrics, criteria) => {
    dispatch(
      UpdateCriteriaRows({
        update: userDecisions?.criteria
          ?.filter((crit) => crit?.id === criteria)
          ?.map(({ rubric, ...rest }) => {
            return { ...rest, rubric: rubrics };
          }),
        decision: decisionId,
      })
    );
  }

  const items = [
    {
      key: "1",
      label: `Criteria`,
      children: (
        <>
          <div className={classes.content}>
            <div className={classes.criteriaTab}>
              <div
                className={classes.criterias}
                onScroll={onCriteriasScrollHandle}
                ref={$criterias}
              >
                {userDecision?.criteria
                  ?.filter((item) => item?.active)
                  ?.map((c) => (
                    <div
                      key={c?.id}
                      className={cc(classes.item, {
                        [classes.active]: activeCriteria?.id === c?.id,
                      })}
                      onClick={() => {
                        setSelectedCriteria(c?.id);
                        setSelectedCriterias(c);
                        setActiveCriteria(c);
                      }}
                    >
                      {c?.title}
                    </div>
                  ))}
              </div>
            </div>

            <div className={classes.separator} />

            <div className={classes.wrapper}>
              <h2 className={classes.contentTitle}>Criteria Content</h2>
              <div className={classes.scoreContainer}>
                <Row>
                  <Col xxl={10} span="24">
                    <div className={classes.scoreWrapper}>
                      <div className={classes.score}>Score</div>

                      <div className={classes.select}>
                        <Select
                          value={activeOptionCriteria?.score}
                          optionCriteria={activeOptionCriteria || {}}
                          curentIndex={false}
                          selectIndex={selectIndex}
                          setSelectIndex={setSelectIndex}
                          secondary
                          isShortList={columnsToShow?.isShortlisted}
                          rubric={activeCriteria?.rubric}
                          setIsTrue={setIsTrue}
                          isTrue={isTrue}
                          onRubricSave={handleRubricSave}
                          activeCriterias={activeCriterias}
                        />
                      </div>
                      <div className={classes.q}>
                        How well does this option fulfill this criteria?
                      </div>
                      {/* <div className={classes.selectInfo}>
                        <span>
                          N/A is when the criteria does not exist for this option
                        </span>
                        <span>
                          0 is when the criteria is completely insufficient
                        </span>
                        <span>10 is when the criteria is fully satisfactory</span>
                      </div> */}
                    </div>
                  </Col>
                  <Col xxl={14} span="24">
                    <div className={classes.factsWrapper}>
                      <div className={classes.heading_div}>
                        <span>Facts</span>
                        <div
                          className={classes.generateWithAI}
                          onClick={() => factModal()}
                          // onClick={() => factModal(criteria)}
                        >
                          Generate with AI
                        </div>
                      </div>

                      <textarea
                        value={facts || ""}
                        placeholder="Enter some facts about this option..."
                        onChange={(e) => onFactsChangeHandle(e)}
                        readOnly={userDecision?.is_sample}
                      />

                      <div className={classes.factsFooter}>
                        {(activeOptionCriteria?.facts !== facts) ? <Button onClick={handleFactsReset} styleType="link-primary">Cancel</Button> : null}
                        <Button disabled={activeOptionCriteria?.facts === facts} onClick={handleFactsSave}>Save</Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* </div>

                    <div className={classes.factsWrapper}>
                      <span>Facts</span>
                      <textarea
                        value={facts}
                        placeholder="Enter some facts about this option..."
                        onChange={(e) => onFactsChangeHandle(e)}
                      />
                    </div> */}

              <Compare
                isShortList={columnsToShow?.isShortlisted}
                activeOptionCriteria={userDecision?.options
                  ?.find((item) => item?.id == optionId)
                  ?.option_criteria?.find((item) =>
                    item?.criteria?.id
                      ? item?.criteria?.id
                      : item?.criteria === activeCriteria?.id
                  )}
                readOnly={userDecision?.is_sample}
                setAiAssistant={setAiAssistant}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: `Summary`,
      children: <CompareSummary optionId={optionId} />,
    },
  ];
  const tabChange = (key) => {};
  return (
    <div>
      <HelpPopup />

      {isEditModalOpen && (
        <EditDecisionModal
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          decision={userDecision}
          isDecisionDetail={true}
        />
      )}
      <AppLayout>
        {userDecision && (
          <DecisionDetailHeader
            options={userDecision?.options}
            setIsShowFinalizeDecisin={setIsShowFinalizeDecisin}
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            setIsShortList={() => {}}
            isShortList={false}
            setIsFinalists={() => {}}
            isFinalists={false}
            isModifyView={false}
          />
        )}
        {width > 1199 && (
          <div className={classes.bodyWrapper}>
            <div className={classes.body}>
              <div className={classes.details}>
                <div className={classes.optionName}>
                  <p>{option?.name}</p>
                </div>

                <div className={classes.optionImgWrapper}>
                  <img
                    className={classes.optionImg}
                    src={option?.image || Placeholder}
                    width={250}
                    alt=""
                  />

                  {/* No need to have edit option for now */}
                  {/* <img onClick={()=>setIsAddImg(true)} className={classes.editImg} src={require('./Edit.png')} alt=""/> */}
                </div>
                <span className={classes.detailHeading}>Details</span>
                <div className={classes.link}>
                  <div className="d-flex align-items-center">
                    <span>Link</span>
                    <button
                      className={classes.edit}
                      type="button"
                      title={option?.link ? "Change link" : "Set link"}
                    >
                      <img
                        onClick={() => setIsAddLink(!isAddLink)}
                        width={24}
                        src={require("./Edit.png")}
                        alt=""
                      />
                    </button>
                  </div>
                  {option?.link && (
                    <a href={option.link} target="_blank">
                      {option?.link}
                    </a>
                  )}
                </div>

                <div className={classes.notes}>
                  <span>Notes</span>
                  <textarea
                    value={notes || ""}
                    placeholder="..."
                    onChange={onNotesChangeHandle}
                  />

                  <div className={classes.notesFooter}>
                    {(userDecision?.options?.find((o) => o?.id === +optionId)?.notes !== notes) ? <Button onClick={handleResetNotes} styleType="link-primary">Cancel</Button> : null}
                    <Button disabled={userDecision?.options?.find((o) => o?.id === +optionId)?.notes === notes} onClick={handleUpdateNotes}>Save</Button>
                  </div>
                </div>
              </div>
              {/* <div className={classes.criteria}>
                <div className={classes.title}>{option.name}</div>
                <span>Criteria</span>
                <div className={classes.criterias}>
                  {userDecision?.criteria
                    ?.filter((item) => item?.active)
                    ?.map((c) => (
                      <div
                        key={c.id}
                        className={cc(classes.item, {
                          [classes.active]: activeCriteria.id === c.id,
                        })}
                        onClick={() => {
                          setActiveCriteria(c);
                        }}
                      >
                        {c?.title}
                      </div>
                    ))}
                </div>
              </div> */}
              <>
                <Tabs
                  defaultActiveKey="1"
                  items={items}
                  className={`${classes.tabsWrapper} tabsWrapper tabbs`}
                  onChange={tabChange}
                  width="100%"
                />
              </>

              {/* <div className={classes.content}>
                <h2 className={classes.contentTitle}>Content</h2>
                <div className={classes.score}>Score</div>
                <div className={classes.q}>
                  How well does this option fulfill this criteria?
                </div>

                <div className={classes.scoreWrapper}>
                  <div className={classes.select}>
                    <Select
                      value={activeOptionCriteria?.score}
                      optionCriteria={activeOptionCriteria || {}}
                      curentIndex={false}
                      selectIndex={selectIndex}
                      setSelectIndex={setSelectIndex}
                      secondary
                      isShortList={columnsToShow?.isShortlisted}
                    />
                  </div>
                  <div className={classes.selectInfo}>
                    <span>
                      N/A is when the criteria does not exist for this option
                    </span>
                    <span>
                      0 is when the criteria is completely insufficient
                    </span>
                    <span>10 is when the criteria is fully satisfactory</span>
                  </div>
                </div>

                <div className={classes.factsWrapper}>
                  <span>Facts</span>
                  <textarea
                    value={facts}
                    placeholder="Enter some facts about this option..."
                    onChange={(e) => onFactsChangeHandle(e)}
                  />
                </div>

                <Compare
                  isShortList={columnsToShow?.isShortlisted}
                  activeOptionCriteria={userDecision?.options
                    ?.find((item) => item?.id == optionId)
                    ?.option_criteria?.find((item) =>
                      item?.criteria?.id
                        ? item?.criteria?.id
                        : item?.criteria === activeCriteria?.id
                    )}
                />
              </div> */}
            </div>
          </div>
        )}
        {width < 1199 && (
          <div className={classes.bodyTablet}>
            <div className={classes.detailsTablet}>
              <div>
                <img
                  className={classes.optionImgTablet}
                  src={option.image || Placeholder}
                  width={250}
                  alt=""
                />
              </div>

              <div className={classes.deteilContentWrapper}>
                <span>{option.name}</span>
                <div style={{ display: "flex" }}>
                  <span className={classes.linkTablet}>Link</span>
                  <button
                    className={classes.edit}
                    type="button"
                    title={option?.link ? "Change link" : "Set link"}
                  >
                    <img
                      onClick={() => setIsAddLink(!isAddLink)}
                      width={24}
                      src={require("./Edit.png")}
                      alt=""
                    />
                  </button>
                </div>
                {option?.link && (
                  <a href={option.link} target="_blank">
                    {option?.link}
                  </a>
                )}
                <div className={classes.notesTablet}>
                  <span>Notes</span>
                  <textarea
                    value={notes || ""}
                    placeholder="..."
                    onChange={onNotesChangeHandle}
                    disabled={userDecision?.is_sample}
                  />

                  <div className={classes.notesFooter}>
                    {(userDecision?.options?.find((o) => o?.id === +optionId)?.notes !== notes) ? <Button onClick={handleResetNotes} styleType="link-primary">Cancel</Button> : null}
                    <Button disabled={userDecision?.options?.find((o) => o?.id === +optionId)?.notes === notes} onClick={handleUpdateNotes}>Save</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.criteriasTablet}>
              <span className={classes.titleTablet}>Criteria</span>
              <div className={classes.criteriasWrapperTablet}>
                {userDecision?.criteria?.map((c) => (
                  <div
                    onClick={() => {
                      setSelectedCriteria(c?.id);
                      setActiveCriteria(c);
                    }}
                    className={cc(classes.criteriaTablet, {
                      [classes.active]: c?.id === activeCriteria?.id,
                    })}
                    key={c?.id}
                  >
                    <>{c?.title}</>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.contentTablet}>
              <h2 className={classes.contentTitleTablet}>Content</h2>
              <div className={classes.scoreTablet}>Score</div>
              <div className={classes.qTablet}>
                How well does this option fulfill this criteria?
              </div>
              <div className={classes.scoreWrapperTablet}>
                <div className={classes.selectTablet}>
                  <Select
                    value={activeOptionCriteria?.score}
                    optionCriteria={activeOptionCriteria || {}}
                    curentIndex={false}
                    selectIndex={selectIndex}
                    setSelectIndex={setSelectIndex}
                    secondary
                    rubric={activeCriteria?.rubric}
                    onRubricSave={handleRubricSave}
                  />
                </div>
              </div>

              <div className={classes.factsWrapperTablet}>
                <span>Facts</span>
                <textarea
                  value={facts || ""}
                  placeholder="Enter some facts about this option..."
                  onChange={(e) => onFactsChangeHandle(e)}
                />
              </div>

              <Compare
                activeOptionCriteria={userDecision?.options
                  ?.find((item) => item?.id == optionId)
                  ?.option_criteria?.find((item) =>
                    item?.criteria?.id
                      ? item?.criteria?.id
                      : item?.criteria === activeCriteria?.id
                  )}
                setAiAssistant={setAiAssistant}
              />
            </div>
          </div>
        )}
        {aiAssistant && (
          <AIAssistantModal
            criteria={userDecision?.criteria?.filter((item) => item?.active)}
            setOptionCriteriaProsCons={setOptionCriteriaProsCons}
            optionId={activeOptionCriteria?.id}
            onClose={onCloseAIModal}
            selectedCriteria={selectedCriterias}
          />
        )}
      </AppLayout>

      {isShowFinalizeDecisin && (
        <FinalizeDecision
          decision={userDecision}
          options={userDecision?.options}
          onClose={() => setIsShowFinalizeDecisin(false)}
          onShowWinner={() => setIsShowWinner(true)}
        />
      )}

      {isShowWinner && (
        <WinnerModal
          decision={userDecision}
          onClose={() => setIsShowWinner(false)}
        />
      )}

      <ManageCriteria userCriteria={criterias} setCriteriasX={setCriterias} />

      {isAddLink && (
        <AddOptionLink
          setIsAddLink={setIsAddLink}
          setOption={setOption}
          value={"link"}
          option={option}
          isShortList={columnsToShow?.isShortlisted}
        />
      )}
      {isFactModalOpen && (
        <FactsModal
          optionCriteria={activeOptionCriteria}
          // criteria={criterias}
          handleApply={(selectedCriteria, facts) =>
            handleFactsModal(selectedCriteria, facts)
          }
          // onClose={() => setIsFactModalOpen(false)}
          // selectedCriteria={selectedCriteria}
          // onSelectedCriteriaChange={(name, value) => setSelectedCriteria(value)}
          ////
          criteria={userDecision?.criteria?.filter((item) => item?.active)}
          onSelectedCriteriaChange={setOptionCriteriaProsCons}
          optionId={activeOptionCriteria?.id}
          onClose={() => setIsFactModalOpen(false)}
          selectedCriteria={selectedCriterias}
        />
      )}

      {/*{isOpenUpload && <UploadModal setIsUpload={setIsUpload} setOption={setOption} option={option} decisionId={decisionId}/>}*/}
      {/*/!*{isAddImg && <UploadImage option={option}/>}*!/*/}
    </div>
  );
};

export { OptionDetail };
