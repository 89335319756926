import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import { DatePicker, Input, Modal, Select } from "antd";
import { ReactComponent as Cross } from "assets/svg/crossBox.svg";
import { ReactComponent as CalanderIcon } from "assets/svg/calander.svg";
import { ReactComponent as ArrowDown } from "assets/svg/arrowDownGray.svg";
import { toast } from "react-toastify";
import { durationList } from "constants";

import styles from "./styles.module.scss";
import moment from "moment";
import {
  createTask,
  getAllTask,
  getProjectList,
  getProjectUsers,
} from "../../../modules/actions/UserActions";
import { LoadingOutlined } from "@ant-design/icons";

export default function CreateTaskModel({ onClose, visible }) {
  const dispatch = useDispatch();
  const [isViewMore, setIsViewMore] = useState(false);
  const [assignee, setAssignee] = useState(null);

  const { projectUser, loading, projectList } = useSelector(
    (state) => state.user.projectOwners
  );

  const flatArray = Array.isArray(projectList)
    ? projectList.flat(Infinity)
    : [];

  const AssignOptions = projectUser?.map((user) => ({
    label: user.email,
    value: user.id,
    initials: user.name
      ? user.name.match(/\b\w/g).join("")
      : user.email[0].toUpperCase(),
  }));

  const ProjectOption = flatArray?.map((project) => ({
    label: project.name,
    value: project.id,
  }));

  const [values, setValues] = useState({
    name: "",
    context: "",
    start_line: "",
    dead_line: "",
    weight: null,
    start: null,
    current: null,
    target: null,
    duration: null,
    project_plan: null,
    category: null,
    responsible_person: null,
  });

  const filterProject = flatArray?.find(
    (project) => project.id === values?.project_plan
  );

  const CategoryOption = filterProject?.action_categories?.map((category) => ({
    label: category.name,
    value: category.id,
  }));

  const userName = assignee && assignee?.substring(0, 2);

  const handleClose = () => {
    onClose();
    setValues({
      name: "",
      context: "",
      start_line: "",
      dead_line: "",
      weight: null,
      start: null,
      current: null,
      target: null,
      duration: "",
      project_plan: null,
      category: null,
      responsible_person: null,
    });
    setAssignee(null);
    setIsViewMore(false);
  };

  const weightOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
  ];

  const handleChnage = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const momentDeadLine = moment(values?.dead_line, "YYYY-MM-DD").format(
    "YYYY-MM-DD"
  );
  const momentStartLine = moment(values?.start_line, "YYYY-MM-DD").format(
    "YYYY-MM-DD"
  );

  const handleSave = () => {
    if (values.name === "") {
      return toast.error("Please enter task name");
    }

    if (isViewMore && momentDeadLine && momentDeadLine < momentStartLine) {
      toast.error("End date should be greater than start date");
      return;
    }

    let payload = {
      name: values.name,
      context: values.context,
      start_line: values.start_line,
      dead_line: values.dead_line,
      weight: values.weight,
      start: values.start,
      current: values.current,
      target: values.target,
      duration: values.duration,
      project_plan: values.project_plan,
      category: values.category,
    };

    payload = Object.fromEntries(
      Object.entries(payload).filter(
        ([_, value]) => value !== null && value !== ""
      )
    );

    if (isViewMore) {
      dispatch(createTask(payload, handleClose));
    } else {
      dispatch(createTask({ name: values.name }, handleClose));
    }
  };

  useEffect(() => {
    dispatch(getProjectList());
    dispatch(getProjectUsers());
    dispatch(getAllTask());
  }, []);

  return (
    <Modal
      footer={null}
      closable={false}
      title={false}
      open={visible}
      centered
      width={750}
      className="disablePadding"
    >
      <div className={styles.custumModel}>
        <div className={styles.modelHeader}>
          <h3>Add Task</h3>
          <Cross className={"c-pointer"} onClick={handleClose} />
        </div>

        <div className={styles.main}>
          <div className={styles.container}>
            <label htmlFor="">Task Name</label>
            <Input
              size="large"
              name="name"
              value={values.name}
              onChange={(e) => {
                let input = e.target.value;

                if (input?.startsWith(" ")) {
                  input = input.trim();
                }

                if (input.length > 255) {
                  input = input.slice(0, 255);
                }

                handleChnage({ target: { name: "name", value: input } });
              }}
            />
          </div>

          <div className={styles.viewLess}>
            <h4 onClick={() => setIsViewMore(!isViewMore)}>
              {isViewMore ? "View Less" : "View More"}
            </h4>
          </div>

          {isViewMore && (
            <div className={styles.viewMore}>
              <div className={styles.container}>
                <label htmlFor="">Note</label>
                <textarea
                  name="context"
                  placeholder="Add note"
                  value={values.context}
                  onChange={(e) => {
                    let input = e.target.value;

                    if (input.length > 500) {
                      input = input.slice(0, 500);
                    }

                    handleChnage({
                      target: { name: "context", value: input },
                    });
                  }}
                ></textarea>
              </div>

              <div className={styles.boxs}>
                <div className={styles.box}>
                  <label htmlFor="">Start Date</label>
                  <DatePicker
                    size="large"
                    allowClear
                    placeholder="MM/DD/YYYY"
                    className="antd-suffixIcon"
                    inputReadOnly={true}
                    suffixIcon={<CalanderIcon />}
                    value={
                      values?.start_line ? moment(values?.start_line) : null
                    }
                    disabledDate={(current) =>
                      current && current > moment(values.dead_line)
                    }
                    onChange={(date, value) => {
                      setValues({
                        ...values,
                        start_line: date
                          ? moment(date).format("YYYY-MM-DD")
                          : null,
                      });
                    }}
                  />
                </div>

                <div className={styles.box}>
                  <label htmlFor="">Due Date</label>
                  <DatePicker
                    size="large"
                    allowClear
                    placeholder="MM/DD/YYYY"
                    className="antd-suffixIcon"
                    suffixIcon={<CalanderIcon />}
                    value={values?.dead_line ? moment(values?.dead_line) : null}
                    disabledDate={(current) =>
                      current && current < moment(values.start_line)
                    }
                    inputReadOnly={true}
                    onChange={(date, value) => {
                      setValues({
                        ...values,
                        dead_line: date
                          ? moment(date).format("YYYY-MM-DD")
                          : null,
                      });
                    }}
                  />
                </div>

                <div className={styles.box}>
                  <label htmlFor="">Weight</label>

                  <Select
                    size="large"
                    placeholder="--"
                    popupClassName="custom-dropdown"
                    style={{ width: "100%", border: "1px solid #E5E5E5" }}
                    suffixIcon={<ArrowDown />}
                    dropdownStyle={{ maxHeight: 200, overflowY: "auto" }} // Ensure dropdown is scrollable
                    options={weightOptions}
                    value={values.weight}
                    onChange={(value) => {
                      setValues({ ...values, weight: value });
                    }}
                  />
                </div>
              </div>

              <div className={styles.boxs}>
                <div className={styles.box}>
                  <label htmlFor="">Start Value</label>

                  <Input
                    type="number"
                    size="large"
                    name="start"
                    value={values.start}
                    placeholder="--"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "."].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) => {
                      let input = e.target.value;

                      if (input?.startsWith(" ")) {
                        input = input.trim();
                      }

                      if (input.length > 20) {
                        input = input.slice(0, 20);
                      }
                      setValues({ ...values, start: input });
                    }}
                  />
                </div>

                <div className={styles.box}>
                  <label htmlFor="">Current Value</label>

                  <Input
                    type="number"
                    size="large"
                    name="current"
                    value={values.current}
                    placeholder="--"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "."].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) => {
                      let input = e.target.value;

                      if (input?.startsWith(" ")) {
                        input = input.trim();
                      }

                      if (input.length > 20) {
                        input = input.slice(0, 20);
                      }

                      setValues({ ...values, current: input });
                    }}
                  />
                </div>

                <div className={styles.box}>
                  <label htmlFor="">Target Value</label>

                  <Input
                    type="number"
                    size="large"
                    name="target"
                    value={values.target}
                    placeholder="--"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "."].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) => {
                      let input = e.target.value;

                      if (input?.startsWith(" ")) {
                        input = input.trim();
                      }

                      if (input.length > 20) {
                        input = input.slice(0, 20);
                      }

                      setValues({ ...values, target: input });
                    }}
                  />
                </div>
              </div>

              <div className={styles.boxs}>
                <div className={styles.box}>
                  <label htmlFor="">Weekly Duration</label>
                  <Select
                    dropdownMatchSelectWidth={false}
                    size="large"
                    placeholder="-- --"
                    style={{
                      width: "100%",
                      border: "1px solid #E5E5E5",
                    }}
                    suffixIcon={<ArrowDown />}
                    value={values.duration}
                    onChange={(value) => {
                      setValues({ ...values, duration: value });
                    }}
                  >
                    {durationList.map((count, index) => (
                      <Option key={index} value={count.value}>
                        {count.key}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className={styles.box}>
                  <label htmlFor="">Assignee</label>

                  <Select
                    size="large"
                    placeholder="--"
                    style={{
                      width: "100%",
                      border: "1px solid #E5E5E5",
                    }}
                    className="antd-assignee"
                    showSearch
                    value={userName}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    labelInValue
                    onSelect={(value) => {
                      setAssignee(value?.label);
                    }}
                  >
                    {AssignOptions.map((option, index) => (
                      <Select.Option key={index} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className={styles.container}>
                <label htmlFor="">Select Project</label>
                <Select
                  size="large"
                  placeholder="--"
                  style={{ width: "100%", border: "1px solid #E5E5E5" }}
                  suffixIcon={<ArrowDown />}
                  options={ProjectOption}
                  value={values.project_plan}
                  onChange={(value) => {
                    setValues({ ...values, project_plan: value });
                  }}
                />
              </div>

              <div className={styles.container}>
                <label htmlFor="">Select Category</label>
                <Select
                  size="large"
                  placeholder="--"
                  style={{ width: "100%", border: "1px solid #E5E5E5" }}
                  suffixIcon={<ArrowDown />}
                  value={values.category}
                  disabled={values?.project_plan ? false : true}
                  options={CategoryOption}
                  onChange={(value) => {
                    setValues({ ...values, category: value });
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.actions}>
          <button onClick={handleClose}>Cancel</button>
          <button onClick={handleSave} disabled={loading || !values.name}>
            {loading ? <LoadingOutlined /> : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  );
}
